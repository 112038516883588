import React, { useEffect, useState } from "react"
import { Helmet } from 'react-helmet'
import Logo from "../images/DerwishLogo-07.png"
import LogoIcon from "../images/Derwish_Kafa.png"
import Thumbnail from "../images/preview.png"
import { Link } from "gatsby"
import Marquee from "react-fast-marquee";

export default function Header(props) {

  const [isOpen, setIsOpen] = useState(false);
 
  const togglePopup = (e) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  }

  const [sticky, setSticky] = useState(false);

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", () =>
        setSticky(window.pageYOffset > 400)
      );
    }
  }, []);

  return (
  <>
    <Helmet>
      <title>Derwish | DERWISH ON THE WAY FOR THE FUTURE</title>
      <meta charset="utf-8"></meta>
      <meta name="description" content="Derwish | Derwish is a decentralized “SocialFi” platform that enables Web3 users to profit from pools by estimating crypto assets under various titles." />
      <meta property="og:image" content={Thumbnail} />
    </Helmet>

    <div class="sliding-text">
      <Marquee gradient={false} speed={20}>
      <ul>
        <li>DERWISH ON THE WAY!</li>
        <li>IN DERWISH WE TRUST!</li>
        <li>THE WORLD IS YOURS!</li>
        <li>DERWISH ON THE WAY!</li>
        <li>IN DERWISH WE TRUST!</li>
        <li>THE WORLD IS YOURS!</li>
        <li>DERWISH ON THE WAY!</li>
        <li>IN DERWISH WE TRUST!</li>
        <li>THE WORLD IS YOURS!</li>
        <li>DERWISH ON THE WAY!</li>
        <li>IN DERWISH WE TRUST!</li>
        <li>THE WORLD IS YOURS!</li>
        <li>DERWISH ON THE WAY!</li>
        <li>IN DERWISH WE TRUST!</li>
        <li>THE WORLD IS YOURS!</li>
        <li>DERWISH ON THE WAY!</li>
        <li>IN DERWISH WE TRUST!</li>
        <li>THE WORLD IS YOURS!</li>
      </ul>
      </Marquee>
    </div>
  <div class="static-header">
    <header>
    <Link className="logo" to="/" onContextMenu={togglePopup} id="logo">
        <img src={Logo} alt="Derwish"/>
      </Link>
      <ul class="menu">
        <li><Link style={{color: 'white'}} to="https://docs.derwish.xyz">Docs</Link></li>
        <li><Link style={{color: 'white'}} to="">DAO</Link></li>
        <li><Link style={{color: 'white'}} to="https://docs.derwish.xyz/knowledge-center/faq">FAQ</Link></li>
        <li class="dropdown_menu">
          <button class="dropdown_main" style={{color: 'white'}}>
            Community
          </button>
          <ul class="dropdown">
            <li><a href="https://twitter.com/derwishxyz" target="_blank" rel="noreferrer">Twitter</a></li>
            <li><a href="https://discord.gg/JZrYmJbG" target="_blank" rel="noreferrer">Discord</a></li>
            <li><a href="https://t.me/derwishbase" target="_blank" rel="noreferrer">Telegram</a></li>
            <li><a href="#" target="_blank" rel="noreferrer">Announcements</a></li>
          </ul>
        </li>
        <li class="gotoapp">
          <a href="#" class="mainnet_btn" target="_blank" rel="noreferrer">Derwish App</a></li>
      </ul>
    </header>
  </div>
  <div className={`fixed-header ${
          sticky ? "sticky" : ""
        }`}>
    <header>
      <Link to="/" onContextMenu={togglePopup}>
        <img src={LogoIcon} alt="Derwish" />
      </Link>
      <ul class="menu">
      <li><Link to="/resources">Docs</Link></li>
        <li><Link  to="https://docs.derwish.xyz/">DAO</Link></li>
        <li><Link to="https://docs.derwish.xyz/knowledge-center/faq">FAQ</Link></li>
        <li class="dropdown_menu">
          <button class="dropdown_main">
            Community
          </button>
          <ul class="dropdown">
          <li><a href="https://twitter.com/derwishxyz" target="_blank" rel="noreferrer">Twitter</a></li>
            <li><a href="https://discord.gg/JZrYmJbG" target="_blank" rel="noreferrer">Discord</a></li>
            <li><a href="https://t.me/derwishbase" target="_blank" rel="noreferrer">Telegram</a></li>
            <li><a href="#" target="_blank" rel="noreferrer">Announcements</a></li>
          </ul>
        </li>
        <li class="gotoapp">
          <a href="#" class="mainnet_btn" target="_blank" rel="noreferrer">Derwish App</a></li>
      </ul>
    </header>
  </div>

  </>
  )
}
